@import "../../../global.scss";

#body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $preto;

}

#arquiteturaPage {

    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    padding: 0 2em 0 2em;

    .arquiteturaTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 95vw;
        height: 50vh;
        border-radius: 20px;
        background-image: url(../../../imgs/arqFundo.png);
        background-size: cover;

        h1 {
            font-size: 3em;
            color: $branco;
            animation: glitch1 2s infinite;
        }
    
        h1::before {
            content: " ";
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            inset: 0 0 0 0;
            background: $preto;
            z-index: -1;
            transition: transform .3s ease;
          }
          
        h1::before {
            transform: scaleX(0);
            transform-origin: bottom right;
        }
    
        h1:hover::before {
            transform: scaleX(1);
            transform-origin: bottom left;
        }

        p {
            text-align: center;
            margin-left: 1rem;
            color: $branco;
            font-size: 1.5rem;
            animation: glitch1 2s infinite;
        }
    
        p::before {
            content: " ";
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            inset: 0 0 0 0;
            background: $preto;
            z-index: -1;
            transition: transform .3s ease;
          }
          
        p::before {
            transform: scaleX(0);
            transform-origin: bottom right;
        }
    
        p:hover::before {
            transform: scaleX(1);
            transform-origin: bottom left;
        }

    }

    .arquiteturaServices {

        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        .service1 {
            
            display: flex;
            flex-direction: column;
            width: 80vw;
            padding: 1rem;

            h1, p {
                color: $amarelo;
            }

            p {
                text-align: justify;
                text-indent: 2em;
            }
        }
    }

    .arquiteturaPortf {

        width: 80vw;
        background-color: $roxo;
        border-radius: 20px;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 2em;
            text-align: center;
            margin: 1em 0 1em 0;
            color: $amarelo;
            animation: glitch1 2s infinite;
        }
    
        h1::before {
            content: " ";
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            inset: 0 0 0 0;
            background: $preto;
            z-index: -1;
            transition: transform .3s ease;
          }
          
        h1::before {
            transform: scaleX(0);
            transform-origin: bottom right;
        }
    
        h1:hover::before {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
        
        .portfolio1 {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                margin: auto;
                width: 60vw;
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            p {
                margin: 1em;
                text-align: center;
                font-size: 1em;
                color: $amarelo;
            }
        }

    }

    .otherProjects {

        padding: 0.5em;

        h1 {
            font-size: 2em;
            text-align: center;
            margin: 1em 0 1em 0;
            color: $amarelo;
            animation: glitch1 2s infinite;
        }
    
        h1::before {
            content: " ";
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            inset: 0 0 0 0;
            background: $roxo;
            z-index: -1;
            transition: transform .3s ease;
          }
          
        h1::before {
            transform: scaleX(0);
            transform-origin: bottom right;
        }
    
        h1:hover::before {
            transform: scaleX(1);
            transform-origin: bottom left;
        }

        a {
            background-color: $amarelo;
            color: $roxo;
            margin-left: 1em;
            padding: 0.5em;
            border-radius: 5px;
            text-decoration: none;
            transition: all 0.3s;
        }

        a:hover {
            background-color: $branco;
        }

        a:active {
            background-color: $roxo;
            transition: all 0.1s;
            color: $amarelo;
        }
    }
}

@media (max-width: 768px) {
    #body {
        overflow-x: hidden;

        #arquiteturaPage {
            .arquiteturaTitle {
                h1 {
                    font-size: 1.6em;
                    margin-left: 0;
                    width: 80vw;
                    text-align: center;
                    background-color: $preto;
                }
        
                p {
                    font-size: 1em;
                    text-align: center;
                    background-color: $preto;
                }
            }

            .arquiteturaServices {
                .service1 {
                    h1 {
                        font-size: 1.6em;
                        margin-left: 0;
                        width: 80vw;
                    }
            
                    p {
                        font-size: 1.1em;
                    }
                }
            }

            .arquiteturaPortf {
                h1 {
                    font-size: 1.6em;
                    margin-left: 0;
                    width: 80vw;
                }
            }

            .otherProjects {
                display: flex;
                flex-direction: column;

                a {
                    margin-left: 0;
                    margin-top: 0.5em;
                }
            }
        }
    }
}