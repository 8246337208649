@import "../../global.scss";

* {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
}

#mainAboutUs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $preto;
    width: 100%;
    height: 100%;
}

#heroSectionAbout {
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    margin-top: 100px;
    padding: 25px;
    text-indent: 2em;

    .rowObjects {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .collumnObjects {
        display: flex;
        flex-direction: column;
        align-items: left;
        width: 100%;

        .callToActionMembers {

            z-index: 999;
            background-color: $roxo;
            padding: .8rem 0;
            width: 60%;
            border-radius: 5px;

        }
    }

    .heroImgWrapper {

        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        width: 25vw;
        height: 80vh;

        img {
            object-fit: contain;
            height: 150vh;
            width: auto;
            select: none;
            -webkit-user-drag: none;
        }

    }

    h1 {
        color: $roxo;
        font-size: 4em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        text-align: left;
        z-index: 1;

        animation: glitch1 2s infinite;
    }

    h1::before {
        content: " ";
        display: block;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        inset: 0 0 0 0;
        background: #d4d4d4;
        
        z-index: -1;
        transition: transform .3s ease;
      }
      
    h1::before {
        transform: scaleX(0);
        transform-origin: bottom right;
    }

    h1:hover::before {
        transform: scaleX(0.8);
        transform-origin: bottom left;
    }

    a {
        color: $branco;
        cursor: pointer;
        padding: 0 3px;
        font-weight: bold;
        text-decoration: none;
    }

    p {
        color: $amarelo;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 1.1em;
        z-index: 1;
        text-align: left;
        width: 50vw;
    }
}

#sectionWhoWeAre {

    .MVV {

        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 20px;
        flex-direction: column;

        h1 {
            color: $roxo;
            font-size: 3em;
            font-weight: bold;
            text-align: right;

            animation: glitch1 2s infinite;
        }

        h1::before {
            content: " ";
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            inset: 0 0 0 0;
            background: #d4d4d4;
            
            z-index: -1;
            transition: transform .3s ease;
          }
          
        h1::before {
            transform: scaleX(0);
            transform-origin: bottom right;
        }
    
        h1:hover::before {
            transform: scaleX(1);
            transform-origin: bottom left;
        }

        p {
            color: $amarelo;
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: bold;
            z-index: 1;
            text-align: justify;
            width: 50vw;
            text-indent: 2em;
        }
    }
    
    .missionValuesEyesight {

        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        margin-bottom: 10vh;
        width: 100vw;

        .cardM {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 1em;
            text-align: left;
            margin: 0 10px 10px 10px;
            width: 25vw;
            height: 25vh;
            border-radius: 20px;
            background-image: url('../../imgs/FundoMissao.png');
            background-size: cover;
            background-color: $roxo;
            transition: all 0.5s;
        }

        .cardV1 {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 1em;
            text-align: left;
            margin: 0 10px 10px 10px;
            width: 25vw;
            height: 25vh;
            border-radius: 20px;
            background-image: url('../../imgs/FundoVisao.png');
            background-size: cover;
            background-color: $roxo;
            transition: all 0.5s;
        }

        .cardV2 {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 1em;
            text-align: left;
            margin: 0 10px 10px 10px;
            width: 25vw;
            height: 25vh;
            border-radius: 20px;
            background-image: url('../../imgs/FundoValores.png');
            background-size: cover;
            background-color: $roxo;
            transition: all 0.5s;
        }

        .cardM:hover {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

        .cardV1:hover {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

        .cardV2:hover {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
        

        h3, p, tr, td {

            color: $branco;
            opacity: 0.9;

        }

        b {
            color: $amarelo;
            font-weight: bold;
            font-size: 1.2em;
        }
         
        h3 {
            text-align: center;
            animation: glitch1 2s infinite;
            font-weight: bold;
            font-size: 1.5em;
        }
        
        h3::before {
            content: " ";
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            inset: 0 0 0 0;
            background: $preto;
            z-index: -1;
            transition: transform .3s ease;
          }
          
        h3::before {
            transform: scaleX(0);
            transform-origin: bottom right;
        }
    
        h3:hover::before {
            transform: scaleX(1);
            transform-origin: bottom left;
        }

    }
}

#membersTestimonial {

    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    #textIntroTestimonial {

            color: $roxo;
            font-size: 4em;
            margin-block-start: 0.67em;
            margin-block-end: 0.67em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: bold;
            text-align: left;
            z-index: 1;
    
            animation: glitch1 2s infinite;
    
            &::before {
            content: " ";
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            inset: 0 0 0 0;
            background: #d4d4d4;
            
            z-index: -1;
            transition: transform .3s ease;
          }
          
        &::before {
            transform: scaleX(0);
            transform-origin: bottom right;
        }
    
        &:hover::before {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
        
    }

    .membersCardTestimonialWrapper{

        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .membersCardTestimonial {

            display: flex;
            flex-direction: column;
            width: 90%;
            height: 85%;
            border-radius: 15px;
            margin: 1rem 0;
            background: rgb(104,12,126);
            background: linear-gradient(0deg, rgba(104,12,126,1) 0%, rgba(85,0,143,1) 100%);

            .membersTestimonialInfos {

                display: flex;
                flex-direction: row;
                width: 100%;
                height: auto;
                padding: 1rem 0 0 0;
    
                .imageTestimonialWrapper {
    
                    display: flex;
                    // position: absolute;
                    width: 100px;
                    height: 100px;
                    margin-left: 1rem;

                    img {

                        height: auto;
                        width: auto;
                        border-radius: 100px;
                        -webkit-user-drag: none;
  
                    }
    
                }

                .testimonialMemberInfo {

                    display: flex;
                    position: relative;
                    flex-direction: row;
                    margin-left: 2rem;
                    width: 100%;

                    .testimonialMembersTextInfos {

                        h1 {

                            font-weight: 400;
                            font-size: 2rem;
                            color: $branco;

                        }

                        h4{

                            font-weight: 500;
                            font-size: 1.2rem;
                            color: $amarelo;

                        }
                    }

                    .testimonialImageWrapper {

                        display: flex;
                        background-color: transparent;
                        width: 50px;
                        height: 50px;
                        border-radius: 100px;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        position: absolute;
                        right: 2rem;

                        &:hover{

                            background: rgba(255, 255, 255, 0.13)

                        }
                        
                        img {
    
                            width: 60%;
                            height: 60%;
    
                        }
                            
                    }

                }
                
            }

            .testimonialTextWrapper {

                display: flex;
                width: 100%;
                height: auto;
                // align-self: center;

                p {

                    padding: 1rem;
                    text-align: justify;
                    line-height: 1.4rem;
                    color: $branco;

                }
    
            }
            
        }
        
    }

}

@media screen and (max-width: 768px) {

    #heroSectionAbout {

        width: auto;
        overflow-x: hidden;

        .rowObjects {

            margin: 0 auto;

            .collumnObjects {

                // background-color: red;
                display: flex;
                margin: 0;
                padding: 0;
                width: auto;
                align-items: center;
                justify-content: center;

                h1 {
                    font-size: 2em;
                    margin-left: 0;
                    width: 80vw;
                    background-color: #d4d4d4;
                }

                p {

                    width: 100%;
                    line-height: 1.4rem;
                    // text-align: justify;

                }
                
            }

            .heroImgWrapper {

                display: none;
                justify-content: center;
                align-items: center;
                width: 20vw;
                height: 80vh;
                // background-color: blue;

                img {

                    // display: none;
                    object-fit: contain;
                    height: 90vh;
                    width: auto;
                    -webkit-user-drag: none;

                }

            }

            .callToActionMembers {
    
                width: 70%;
                text-align: center;
                margin: 0;
                text-indent: 0;
                font-size: 1.1rem;
    
            }
        }

    }

    #sectionWhoWeAre {

        margin-top: 2rem;

        .MVV {
            
            width: auto;
    
            p {
               
                width: auto;

            }
        }

        .missionValuesEyesight {

            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 4vh 0 10vh 0;
            width: 100vw;
    
            .cardM, .cardV1, .cardV2{

                width: 70vw;
                height: 25vh;
                margin-bottom: 3rem;

            }

            .cardV2 {

                margin-bottom: 0;

            }
        }
    }

    #membersTestimonial {

        width: auto;
    
        #textIntroTestimonial {
    
        font-size: 2em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        text-align: left;
        z-index: 1;
        padding: 0 1rem;
        margin-bottom: 2rem;
        align-self: center;

    }
    
        .membersCardTestimonialWrapper{
    
            .membersCardTestimonial {
    
                .membersTestimonialInfos {
    
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    height: auto;
                    padding: 1rem 0 0 0;
                    margin-top: 2rem;
        
                    .imageTestimonialWrapper {
        
                        display: flex;
                        width: 100px;
                        height: 100px;
                        margin-left: 1rem;
    
                        img {
    
                            height: auto;
                            width: auto;
                            border-radius: 100px;
                            -webkit-user-drag: none;
      
                        }
        
                    }
    
                    .testimonialMemberInfo {
    
                        display: flex;
                        flex-direction: row;
                        margin-right: 1rem;
                        width: 100%;

                        .testimonialMembersTextInfos {

                            h1 {
    
                                font-weight: 400;
                                font-size: 1.5rem;
                                color: $branco;
                                text-align: right;
                                margin-top: .5rem;
    
                            }
    
                            h4{
    
                                font-weight: 500;
                                font-size: 1.1rem;
                                color: $amarelo;
                                text-align: right;
                                margin-top: .7rem;
    
                            }

                        }
    
                        .testimonialImageWrapper {
                            
                            right: 0rem;
                            top: -3rem;

                            // mudar essa paspalhada de imagem aqui
                                
                        }
    
                    }
                    
                }
    
                .testimonialTextWrapper {
    
                    display: flex;
                    width: 100%;
                    height: auto;
                    // align-self: center;
    
                    p {
    
                        padding: 1rem;
                        text-align: justify;
                        line-height: 1.4rem;
    
                    }
        
                }
                
            }
            
        }
    
    }
    
}


    