@import "../../global.scss";

* {

    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;

}

#mainOurMembers {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $preto;
    width: 100%;
    height: auto;
    
    #membersFeedbackSection {

        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 8rem;

        h1 {
            text-align: center;
            margin-left: 1rem;
            color: $amarelo;
            margin-top: 5vh;
            font-size: 2rem;
            animation: glitch1 2s infinite;
        }
    
        h1::before {
            content: " ";
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            inset: 0 0 0 0;
            background: $roxo;
            z-index: -1;
            transition: transform .3s ease;
          }
          
        h1::before {
            transform: scaleX(0);
            transform-origin: bottom right;
        }
    
        h1:hover::before {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
    
        .membersFeedbackIntro {

            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            text-align: center;

            p {

                color: $amarelo;
                padding: 2rem 6rem 1rem 6rem;
                line-height: 1.5rem;

            }
    
            h1 {
    
                color: $roxo;
                font-size: 3rem;
                font-weight: bold;
                text-align: right;
                animation: glitch1 2s infinite;
                
            }
    
            h1::before {
                content: " ";
                display: block;
                position: absolute;
                top: 0; right: 0; bottom: 0; left: 0;
                inset: 0 0 0 0;
                background: #d4d4d4;
                
                z-index: -1;
                transition: transform .3s ease;
              }
              
            h1::before {
                transform: scaleX(0);
                transform-origin: bottom right;
            }
        
            h1:hover::before {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
    
        }
    
        .membersFeedback {
    
            display: flex;
            justify-content: space-around;
            margin: 4rem 0;
            align-items: center;
            justify-content: center;
            width: 100vw;
            flex-wrap: wrap;
    
            .membersCard {
    
                display:flex;
                width: 300px;
                height: 420px;
                flex-direction: column;
                justify-content: center;
                border: 1px solid $roxo;
                align-items: center;
                text-align:center;
                margin: 0 3rem 1rem 3rem;
                place-self: center;
                place-items: center;
    
            }

            #cardProjects {

                margin: 0 1rem 1rem 1rem;

            }
    
        }
    
        .membersFeedbackText {
    
            display: flex;
            flex-direction: column;
            color: transparent;
            position: relative;
            margin: 0 auto;
            width: 95%;
            height: 97%;
            user-select: none;
    
            h3 {
    
                margin-top: 1rem;
                font-size: 1.2rem;
                text-transform: uppercase;
    
            }
    
            h5 {
    
                font-weight: 500;
                font-size: .9rem;
                margin-top: .3rem;
    
            }
    
            p {
    
                margin-top: 3rem;
                padding: 0 1rem;
                font-weight: 400;
                
            }
    
            h3, h5, p {
    
                z-index: 999;
                
            }
    
            &:hover {
    
                color: $branco;
                transition: .5s;
    
                h5 {
    
                    color: $amarelo;
                    transition: .5s;
    
                }
    
            }
    
            &:hover img {
    
                filter: grayscale(100%);
                transition: .5s;
                opacity: 0.3;
    
            }
    
                img {
    
                    display: flex;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    filter: grayscale(0%);
    
                    &:hover {
    
                        filter: grayscale(100%);
                        transition: .5s;
                        opacity: 0.3;
    
                    }
                    
                }
    
                .socialMediaMembers {
    
                    display: flex;
                    visibility: hidden;
                    position: absolute;
                    z-index: 99999;
                    width: 100%;
                    bottom: 1rem;
                    justify-content: space-around;
    
                    .socialMediaWrapper {
    
                        display: flex;
                        position: relative;
                        width: 40px;
                        height: 40px;
                        border-radius: 200px;
                        background-color: $roxo;
                        opacity: 1;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
    
                    }
    
                    img {
    
                        display: flex;
                        width: 60%;
                        opacity: 1;
                        object-fit: contain;
                        filter: grayscale(0%);
    
                    }
    
            }
    
            &:hover .socialMediaMembers {
    
                visibility: visible;
    
            }
    
        }
    }

}

@media screen and (max-width: 768px) {
    #mainOurMembers {
        overflow-x: hidden;
    }

    #membersFeedbackSection {

        h1 {
            font-size: 1em;
            width: 80vw;
        }
        
        .membersFeedbackIntro {

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h1 {
                font-size: 1.6em;
                margin-left: 0;
                background-color: #d4d4d4;
                width: 80vw;
            }

            p {
                font-size: 1em;
                padding: 0;
                width: 80vw;
                text-align: justify;
                text-indent: 2em;
            }
        }
    }
}