@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import "../../global.scss";

.header {

    height: 10vh;
    width: 100vw;
    background-color:rgba(0,0,0,.01);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'Bebas Neue', sans-serif;
    left: 0;
    position: fixed;
    -webkit-transition: all ease-out .5s;
    -moz-transition: all ease-out .5s;
    -o-transition: all ease-out .5s;
    transition: all ease-out .5s;
    z-index: 1000;
    
}

header.active {
    backdrop-filter: blur(3px)
}

.logo {
    padding-left: 50px;
    padding-top: 15px;
    //padding-left: 50px;
    display: flex;
    align-items: center;

}

.logo img {
    height: 6vh;
}

.logo a {
    cursor: pointer;
}

.menu {

    width: 70vw;
    display: flex;
    flex-direction: row;

}

.menu ul {

    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.menu li {

    list-style: none;
    color: #DBB826;
    padding: 5px;
    font-size: 13pt;
    cursor: pointer;

}

.menu a {

    //background-color: $amarelo;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    padding: .5rem 1rem;
    text-decoration: none;
    color: $branco;

}

.menu a:hover {

    color: #DBB826;

}

.menu-mobile {

    display: none;

}

.sandwich {

    display: none;

}

.sandwich input {

    opacity: 0;

}

// 786px
@media screen and (max-width: 1260px) {

    .logo {
        padding-left: 20px;

        img {
            height: 5vh;
        }
    }

    .menu {
        display: none;
    }

    .sandwich {

        display: block;
        display: flex;
        align-items: center;
        padding-right: 20px;

    }

    .sandwich label {

        cursor: pointer;
        position: relative;
        display: block;
        height: 5px;
        width: 50px;

    }

    .sandwich span {

        position: absolute;
        height: 3px;
        width: 30px;
        background: #dbb826;
        transition: .5s ease-in-out;

    }

    .sandwich span:nth-child(1) {

        position: absolute;
        top: -8px;
        height: 3px;
        width: 30px;
        background: #dbb826;

    }

    .sandwich span:nth-child(3) {

        position: absolute;
        top: 8px;
        height: 3px;
        width: 30px;
        background: #dbb826;

    }

    #checkbox:checked + label span:nth-child(1) {

        transform: rotate(-225deg);
        top: 0px;

    }

    #checkbox:checked + label span:nth-child(2) {

        opacity: 0;

    }

    #checkbox:checked + label span:nth-child(3) {

        transform: rotate(225deg);
        top: 0px;

    }

    .menu-mobile {

        width: 100vw;
        display: none;
        align-items: center;
        justify-content: center;

        ul {

            margin-top: 6rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: right;
            list-style: none;
            z-index: 9999;
    
            a {
                color: $branco;
                text-decoration: none;
                margin: 0 0 0.5em 0;
                text-align: center;
                border: 1px solid rgba(255, 255, 255, 0.3);
                border-radius: 5px;
                padding: .5rem 1rem;
            }
    
        }
    }

}