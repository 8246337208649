@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

:root {

    width: 100%;
    overflow-x: hidden;

}

::-webkit-scrollbar {

    background: #FFF;

}

::-webkit-scrollbar-thumb { 

    background-color: #000;
    
}

$amarelo: #dbb826;
$roxo: #55008F;
$cinza: #585755;
$preto: #000;
$branco: #fff;
$vinhoCopa: #821A4B;