@import "../../global.scss";

* {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;

    &::selection{
        background-color: $roxo;
    }
}

#mainHome {

    background-color: black;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;

}

#heroSection {

    .heroWrapper {

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        //text-align: center;
        width: 100%;
        height: 90vh;
        margin-top: 100px;
  
    }

    .heroTitle {
            
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        z-index: 100;
        //background-color: rgb(148, 111, 111);

        

        h1 {
            color: $roxo;
            font-size: 4rem;
            line-height: 4rem;
            //text-shadow: -2px 3px 5px rgba(121, 8, 196, 0.548);
            text-align: right;
            margin-left: 1rem;
            //margin: 2rem;
            //width: 705px;
            // padding: 2rem 3rem 2.25rem;
            // border: 0.3rem solid #fff;
            // border-radius: 2rem;

            // animation: flicker 1.5s infinite alternate;
            animation: glitch1 2s infinite;
        }

        // h1::-moz-selection {
        //     background-color: var($roxo);
        //     color: var($roxo);
        //   }
          
        //   h1::selection {
        //     background-color: var($roxo);
        //     color: var($roxo);
        //   }
          
        //   h1:focus {
        //     outline: none;
        //   }

        h1::before {
            content: " ";
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            inset: 0 0 0 0;
            background: #d4d4d4;
            
            z-index: -1;
            transition: transform .3s ease;
          }
          
        h1::before {
            transform: scaleX(0);
            transform-origin: bottom right;
        }

        h1:hover::before {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
        
        h2 {
            font-weight: 200;
            color: $branco;
            margin-top: 2rem;
            text-align: right;
            margin-right: .7rem;
        }

    }

    .heroImgWrapper {

        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        width: 20vw;
        height: 80vh;

        img {
            object-fit: contain;
            height: auto;
            width: auto;
            -webkit-user-drag: none;
            background-color: $preto;
        }

    }

    .heroData {
                
        display: flex;
        //background-color: rgb(128, 74, 74);
        width: 60vw;
        flex-direction: row;
        margin-top: 2rem;
        margin-left: 1rem;
        //align-items: center;
        font-family: 'Bebas Neue', sans-serif;
        
        
    }

    .heroCard {
        margin: 1rem;   
        text-align: left;
        display: flex;
        flex-direction:row;
        text-shadow: -2px 3px 5px rgba(0, 0, 0, 0.548);
    
        p {
            margin-right: .6rem;
            color: $branco;
            font-size: 5rem;
            font-family: 'Bebas Neue', sans-serif;

            &::selection{
                background-color: #dbb826;
            }
        }

        b {
            font-family: 'Bebas Neue', sans-serif;
            color: $amarelo;
            font-size: 3rem;
            line-height: 2.85rem;
            font-weight: normal;
        }
    }
}

#services {

    display: flex;
    ///align-items: center;
    flex-direction: column;
    background-color: black;
    width: 100%;

    .collumnObjects {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    h1 {

        text-align: left;
        margin-left: 3rem;
        width: 40vw;
        color: $roxo;
        margin-top: 5vh;
        font-size: 3rem;
        //text-shadow: -2px 3px 5px rgba(121, 8, 196, 0.548);

        animation: glitch1 2s infinite;

    }

    h1::before {
        content: " ";
        display: block;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        inset: 0 0 0 0;
        background: #d4d4d4;
        z-index: -1;
        transition: transform .3s ease;
      }
      
    h1::before {
        transform: scaleX(0);
        transform-origin: bottom right;
    }

    h1:hover::before {
        transform: scaleX(1);
        transform-origin: bottom left;
    }

    p {
        text-align: center;
        margin-left: 1rem;
        color: $amarelo;
        margin-top: 5vh;
        font-size: 1.5rem;
        animation: glitch1 2s infinite;
    }

    p::before {
        content: " ";
        display: block;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        inset: 0 0 0 0;
        background: $roxo;
        z-index: -1;
        transition: transform .3s ease;
      }
      
    p::before {
        transform: scaleX(0);
        transform-origin: bottom right;
    }

    p:hover::before {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
    

    h2 {

        color: $branco;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.2rem;
        font-weight: 200;
        background-color: $preto;
        opacity: 0.5;
        padding: 3px;
        
    }


    h3 {
        color: $branco;
        font-family: 'Montserrat', sans-serif;
        font-size: 1rem;
        font-weight: 200;
        background-color: $preto;
        opacity: 0.5;
        padding: 3px;
    }

    .sectorServicearq {

        margin: 20px;
        text-align: center;
        background-color: $roxo;
        width: 30vw;
        height: 20vh;
        background-image: url(../../imgs/arqFundo.png);
        background-size: cover;
        transition: all 0.5s;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 5px;

    }

    .sectorServicecomp {

        margin: 20px;
        text-align: center;
        background-color: $roxo;
        width: 30vw;
        height: 20vh;
        background-image: url(../../imgs/compFundo.png);
        background-size: cover;
        transition: all 0.5s;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 5px;

    }

    .sectorServiceele {

        margin: 20px;
        text-align: center;
        background-color: $roxo;
        width: 30vw;
        height: 20vh;
        background-image: url(../../imgs/eleFundo.png);
        background-size: cover;
        transition: all 0.5s;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 5px;

    }

    .sectorServiceauto {

        margin: 20px;
        text-align: center;
        background-color: $roxo;
        width: 30vw;
        height: 20vh;
        background-image: url(../../imgs/autoFundo2.png);
        background-size: cover;
        transition: all 0.5s;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 5px;

    }

    .sectorServicenegoc {

        margin: 20px;
        text-align: center;
        background-color: $roxo;
        width: 30vw;
        height: 20vh;
        background-image: url(../../imgs/negocFundo.png);
        background-size: cover;
        transition: all 0.5s;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 5px;

    }

    .sectorServicearq:hover {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        h2, h3 {
            opacity: 1;
        }
    }

    .sectorServicecomp:hover {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);

        h2, h3 {
            opacity: 1;
        }
    }

    .sectorServiceele:hover {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);

        h2, h3 {
            opacity: 1;
        }
    }

    .sectorServiceauto:hover {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);

        h2, h3 {
            opacity: 1;
        }
    }

    .sectorServicenegoc:hover {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        h2, h3 {
            opacity: 1;
        }
    }

    .rowObjects {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        a {
            background-color: $amarelo;
            margin-top: 1em;
            color: $roxo;
            padding: 0.5em;
            border-radius: 5px;
            text-decoration: none;
            transition: all 0.3s;
        }

        a:hover {
            background-color: $branco;
        }

        a:active {
            background-color: $preto;
            transition: all 0.1s;
        }
    }

}

#whyChooseUs {

    .chooseTextWrapper {

        display: flex;
        flex-direction: row;
        height: 80vh;
        margin-left: 3rem;
        width: 100vw;

        .chooseText {

            display: flex;
            height: 100%;
            width: 55vw;
            flex-direction: column;
            padding: 1rem 2rem;
            

            h1 {
                font-size: 3rem;
                line-height: 2.8rem;
                color: $amarelo;
                animation: glitch1 2s infinite;
            }

            h1::before {
                content: " ";
                display: block;
                position: absolute;
                top: 0; right: 0; bottom: 0; left: 0;
                inset: 0 0 0 0;
                background: $roxo;
                
                z-index: -1;
                transition: transform .3s ease;
              }
              
            h1::before {
                transform: scaleX(0);
                transform-origin: bottom right;
            }
        
            h1:hover::before {
                transform: scaleX(1);
                transform-origin: bottom left;
            }

            p {

                margin-top: 1rem;
                width: 40vw;
                font-size: 1.3rem;
                color: #fff;
                font-weight: 300;
                line-height: 1.9rem;

            }

        }

        .chooseImgWrapper {

            display: flex;
            width: 40vw;
            height: 100%;
            right: 0;

            img {

                height: auto;
                width: auto;
                transform: rotate(90deg);
            }

        }

    }

}

#feedbackCards {

    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;

    .feedbackCardsWrapper {

        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-bottom: 105px;
        justify-content: space-around;
        

        .feedbackCards {

            margin-top: 2rem;
            align-items: flex-start;
            background-color: #dbb826;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            height: auto;
            justify-content: flex-start;
            padding: 30px;
            width: 400px;

            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;

            border-left: 10px solid $roxo;
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

            .feedbackImgWrapper {

                display: flex;
                width: 80px;
                height: 80px;
                margin-bottom: 1rem;

                img {
                    
                    width: 80px;
                    border-radius: 100px;
                    height: 80px;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    
                    pointer-events: none;
                    -webkit-user-drag: none;
                    -khtml-user-drag: none;
                    -moz-user-drag: none;
                    -o-user-drag: none;

                }

            }

            p {

                margin-top: 1rem;

            }

        }

    }

}

#ourPartners {
    width: 100vw;
    padding: 2rem;
    align-items: center;
    justify-content: center;
    color: #fff;

    //display: flex;

    h1 {
        font-size: 3rem;
        margin-left: 3rem;
        color: $amarelo;
    }

    p {
        margin-top: 2rem;
        font-size: 1.3rem;
        font-weight: 200;
        padding: 0 3rem;
    }
    
    .partnersCardsWrapper {
        padding: 0 2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        z-index: 1000;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .partnersCards {
        margin-top: 2rem;
        width: 20vw;
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        //margin: 1rem;


        .partnersText {
            
            display:flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            margin: 0 auto;

            h2 {
                margin-top: 1.5rem;
                //font-size: 2rem;
                line-height: 1.5rem;
                //padding: 0 2rem;

            }
    
            a {
                text-decoration: none;
                color: $amarelo;
            }
    
            p {
                margin-top: .65rem;
                font-size: 1rem;
            }
            
        }
        
    }

    .partnersImgWrapper {
        
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15vw;
        height: 35vh;

        img {
            
            //margin-bottom: 0rem;
            width: 200px;
            filter:grayscale(1) blur(2px);
            transition: all .5s ease-in-out;
            object-fit: contain;


            &:hover {
                filter: none;
            }
        }
    }

}

@keyframes glitch1 {
    0% {
      transform: none;
      opacity: 1;
    }
    7% {
      transform: skew(-0.25deg, -0.45deg);
      opacity: 0.75;
    }
    10% {
      transform: none;
      opacity: 1;
    }
    27% {
      transform: none;
      opacity: 1;
    }
    30% {
      transform: skew(0.4deg, -0.05deg);
      opacity: 0.75;
    }
    35% {
      transform: none;
      opacity: 1;
    }
    52% {
      transform: none;
      opacity: 1;
    }
    55% {
      transform: skew(-.5deg, 0.1deg);
      opacity: 0.75;
    }
    50% {
      transform: none;
      opacity: 1;
    }
    72% {
      transform: none;
      opacity: 1;
    }
    75% {
      transform: skew(0.2deg, .5deg);
      opacity: 0.75;
    }
    80% {
      transform: none;
      opacity: 1;
    }
    100% {
      transform: none;
      opacity: 1;
    }
  }

@media screen and (max-width: 1260px) {

    .heroTitle h1 {
        //background-color: transparent;
        line-height: 1rem;
    }

    .heroData {
        display:flex;
        flex-direction: column;

        .heroCard {

            display: flex;
            justify-content: center;
            align-items: center;

            p {
                font-size: 4rem;
            }

            b {
                font-size: 2rem;
            }
        }
    }

}

@media screen and (max-width: 768px){

    #mainHome {

        background-color: black;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-x: hidden;
    
    }

    #heroSection {

        .heroTitle {

            h1 {
                font-size: 1.6em;
                margin-left: 2rem;
                line-height: 2rem;
                text-align: left;
                color: $amarelo;
            }

            h2 {
                font-size: 1em;
            }

            .heroData {
                
                display: flex;
                flex-direction: column;

                .heroCard {

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        font-size: 3rem;
                    }

                    b {
                        font-size: 2rem;
                        line-height: 2rem;
                    }
                }
            }
        }

        .heroImgWrapper {

            display: flex;
            justify-content: center;
            align-items: center;
            right: 0;
            width: 10vw;
            height: 80vh;
    
            img {

                object-fit: contain;
                height: auto;
                width: auto;
                -webkit-user-drag: none;
                margin-bottom: 5rem;

            }
    
        }
    

    }

    #services {

        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        h1 {
            font-size: 1.6em;
            margin-left: 0;
            width: 80vw;
            background-color: #d4d4d4;
        }

        h2 {
            font-size: 1em;
        }

        .rowObjects {
            .servicesImgWrapper {

                display: none;

                img {
                    display: none;
                    object-fit: contain;
                    height: auto;
                    width: auto;
                    -webkit-user-drag: none;
                    background-color: $preto;
                }
            }
        }

        .servicesImgWrapper {

            display: flex;
            justify-content: center;
            align-items: center;
            right: 0;
            width: 10vw;
            height: 80vh;
    
            img {
                display: none;
                object-fit: contain;
                height: auto;
                width: auto;
                -webkit-user-drag: none;
                background-color: $preto;
            }
    
        }

        .sectorServicearq, .sectorServiceele,
        .sectorServiceauto, .sectorServicecomp,
        .sectorServicenegoc {

            width: 90vw;
    
        }
    }

    #whyChooseUs {

        height: auto;

        .chooseTextWrapper {

            display: flex;
            flex-direction: row;
            height: auto;
            margin-left: 0;
            width: 100vw;

            .chooseText {

                display: flex;
                height: 100%;
                width: 90vw;
                flex-direction: column;
                padding: 2em;

                h1 {
                    font-size: 1.6em;
                    line-height: 2rem;
                    margin-left: 0;
                    width: 80vw;
                    background-color: $roxo;
                }
        
                p {
                    text-align: justify;
                    text-indent: 2em;
                    width: 80vw;
                    font-size: 1rem;
                    color: #fff;
                    font-weight: 300;
                    line-height: 1.9rem;
                }
            }
        }

        .chooseImgWrapper {
            img {
                display: none;
            }
        }
    }

    #feedbackCards {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

        .feedbackCardsWrapper {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .feedbackCards {
                width: 90vw;
            }
        }
    }

    #ourPartners {

        align-items: center;
        justify-content: center;
        color: #fff;


        h1 {
            font-size: 1.6em;
            text-align: center;
            margin: 0;
        }

        p {
            text-align: center;
            text-indent: 0;
            font-size: 1rem;
            color: #fff;
            font-weight: 300;
            line-height: 1.9rem;
            padding: 0;
            margin: 0;
            padding: 0 2rem;
        }

        .partnersCardsWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .partnersImgWrapper {
                width: 80vw;
                height: 20vh;
                img {
                    filter: none;
                }
            }

            .partnersCards {
                width: 80vw;

                .partnersText {
                    margin-top: 0.1rem;
                }
            }
        }
    }
}