@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;900&display=swap');

@import '../../global.scss';

#Blog {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;
    background-color: $preto;

    #mainBlog {
    
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow-x: hidden;
        max-width: 100vw;
        width: 100vw;
        margin: 15vh 0;
    
        h1 {

            margin-left: 3rem;
            width: 60vw;
            text-align: center;
            color: $roxo;
            margin-top: 5vh;
            font-size: 3rem;
            //text-shadow: -2px 3px 5px rgba(121, 8, 196, 0.548);
    
            animation: glitch1 2s infinite;
    
        }
    
        h1::before {
            content: " ";
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            inset: 0 0 0 0;
            background: #d4d4d4;
            z-index: -1;
            transition: transform .3s ease;
          }
          
        h1::before {
            transform: scaleX(0);
            transform-origin: bottom right;
        }
    
        h1:hover::before {
            transform: scaleX(1);
            transform-origin: bottom left;
        }

        h3 {
        
            color: white;
            background-color: $amarelo;
            padding: 5px 10px;
            border-radius: 5px;
            text-align: center;
            margin-top: 1rem;
        
        }

        .cardsWrapper {

            display: flex;
            width: 100%;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            a {

                text-decoration: none;

            }

            .blogCard {

                display: flex;
                flex-direction: column;
                width: 330px;
                margin: 2rem 2rem;
                height: 650px;
                background-color: #55008f;
                box-shadow: 7px -4px 0px 1px rgba(219,184,38,1);
                border-radius: 10px;
                transition: ease 0.5s;

                .thumbWrapper {

                    display: flex;
                    width: 100%;
                    height: 330px;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 20px;

                    img {

                        width: 100%;
                        max-height: 100%;
                        object-fit: cover;
                        border-radius: 10px 10px 0 0;

                    }

                }

                .postInfos {

                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    padding: 0 15px;
                    height: 100%;
                    align-items: center;

                    h2 {

                        font-family: 'Poppins', sans-serif;
                        font-size: 1rem;
                        font-weight: 700;
                        color: #fff;
                        text-align: center;

                    }

                    .autorInfos {

                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: center;

                        .pictureWrapper {

                            display: flex;
                            width: 50px;
                            height: 50px;
                            margin-right: 15px;

                            img {

                                width: 100%;
                                height: 100%;
                                border-radius: 100px;
                                object-fit: cover;

                            }

                        }

                        h4 {

                            width: 80%;
                            color: #fff;

                        }

                    }

                    p {

                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #fff;

                    }

                    .hashtags {

                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        margin-top: 1.5rem;
                        letter-spacing: 0px;

                        span {

                            margin: 3px 5px;
                            font-size: 16px;
                            background-color: $amarelo;
                            padding: 2px 4px;
                            border-radius: 2px;
                            color: $roxo;
                            font-weight: 600;
                            cursor: default;

                        }
                    }
                }

                .dateWrapper {

                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    
                    h5 {

                        color: #000;

                    }

                }

                &:hover {

                    transform: translate(0px, -10px);
                    transition: ease 0.5s;

                }

            }
        }
        
    }
}

.caminhoAviao{

    border-radius: 150px;
    width: 100%;
    height: 90%;
    animation: anticlockwise 8s infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 100vw;

}

.caminhoAviao img {

    width: 30px;
    position: absolute;
    top: 0px;

}

.warning {

    position: absolute;
    top: 50%;

}

.warning p {

    font-family: 'Montserrat', sans-serif;
    font-size: 15pt;
    color: #DBB826;

}

.testeee {

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 300px;
    height: 300px;

}


.warning p::after {

    animation: testt 5s infinite;
    content: '';

}

.sendToLinkedin {

    width: 100vw;
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15vh;
    text-align: center;

}

.sendToLinkedin p {

    color: #DBB826;
    font-family: 'Montserrat', sans-serif;

}

.sendToLinkedin a {

    color: #DBB826;
    font-family: 'Montserrat', sans-serif;
    font-weight:bold;
    cursor: pointer;
    text-decoration: none;

}

@keyframes anticlockwise {

    0% {

        transform: rotate(-360deg);

    }
    100% {

        transform: rotate(0deg);

    }

}

@keyframes testt {

    0% {

        content: '.';

    }
    50% {

        content: '..';

    }
    75% {

        content: '...';

    }
    100% {

        content: '';

    }

}

@media screen and (max-width: 768px) {

    #Blog {

        width: auto;
        align-items: center;

        #mainBlog {

            h1 {

                text-align: center;
                width: auto;
                color: $roxo;
                margin-top: 5vh;
                margin-left: 0.5em;
                font-size: 1.6rem;
                background-color: #d4d4d4;
                padding: 0;
        
            }

        }

        .postDiv {

            width: auto;

            h3 {

                width: 80%;
                font-size: 1rem;

            }

            h5{

                // font-size: 12pt;
                // max-width: 100%;
                // margin: 0;

                //pq n consigo mdaaaaaaaaaaaar
            
            }

            img{

                min-width: 70%;
                max-width: 90%;
    
            }

        }    
    }
}