@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import '../../global.scss';

@font-face {

  font-family: Eighty Seven;
  src: url(../../fonts/eightySeven.TTF);

}

#mainPS {

    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: $preto;
    overflow-x: hidden;

    #heroSectionPS {

        .heroWrapperPS {
            
            display: flex;
            width: 100vw;
            height: auto;
            align-items: center;
            justify-content: center;

            .logoPSWrapper {

                display: flex;
                width: auto;
                height: auto;
                position: absolute;
                z-index: 2;
                align-items: center;
                justify-content: center;
                top: 3vh;
                user-select: none;

                img {
                    
                    display: flex;
                    width: 25%;

                }
            }

            .textHome {

                display: flex;
                width: 100vw;
                height: 100vh;
                position: absolute;
                z-index: 2;
                align-items: center;
                justify-content: center;
                top: 00vh;
                user-select: none;
                

                h1 {

                    position: absolute;
                    //font-family: 'Kaushan Script', cursive;
                    font-family: 'Bebas Neue', cursive;
                    //font-family: 'Eighty Seven';
                    font-size: 5rem;
                    letter-spacing: 3px;
                    color: $vinhoCopa;
                    cursor: default;
                    bottom: 32%;
                    text-align: center;
                    -webkit-text-stroke-width: 0.1px;
                    -webkit-text-stroke-color: $branco;
                    

                }

                h4 {

                    position: absolute;
                    font-family: 'Bebas Neue', cursive;
                    letter-spacing: 5px;
                    color: $branco;
                    cursor: default;
                    text-align: center;
                    bottom: 10%;

                }

                a {

                    position: absolute;
                    bottom: -5%;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100px;
                    animation: MoveUpDown 1s linear infinite;
                    outline: none;
                      
                      @keyframes MoveUpDown {
                        0%, 100% {
                          bottom: 0;
                        }
                        50% {
                          bottom: 5px;
                        }
                      }

                    img {

                        width: 50px;

                    }

                }

            }

            .backgroundPS {

                width: 100vw;
                height: 100vh;
                position: relative;
                object-fit: cover;
            }

        }

        .sendBackHome {

            position: absolute;
            top: 5%;
            left: 2%;
            width: 15%;
            z-index: 2;
            padding: .1rem .2rem;
            border-radius: 10px;

            img{
                &:hover {

                    background-color: $roxo;
                    color: $branco;
                    transition: all .3s;
    
                }
            }
            
            
        }

    }

    #PSInfosSection {

        .introPSInfos {

            padding: 0 2rem;

            h1 {

                text-align: center;
                padding: 2rem 0;
                font-size: 3.5rem;
                color: $amarelo;
                line-height: 4rem;

            }

            p {

                color: $branco;
                text-align: justify;
                line-height: 1.4rem;
                font-weight: 300;
                text-indent: 3em;
                padding: .7rem .5rem;

            }

        }

        #PSPhases {

            display: flex;
            flex-direction: column;
            width: 100vw;
            height: auto; //mudar
            align-items: center;

            h2 {

                text-align: center;
                padding: 2rem 0;
                font-size: 3rem;
                color: $amarelo;
                line-height: 4rem;

            }

            .PSStoryline {

                display: grid; 
                grid-template-columns: 1fr 0fr 1fr; 
                grid-template-rows: 1fr 1fr 1fr 1fr 1fr; 
                gap: 30px 10px; 
                grid-template-areas: 
                "A B ."
                ". C D"
                "E F ."
                ". G H"
                "I J .";
                align-items: initial;
                justify-content: center;
                position: relative;

                .storyline {

                    width: 2px;
                    height: 83%;
                    background-color: #c4c4c4;
                    position: absolute;
                    left: 50%;
                    z-index: 1;

                }

                .boxStoryline {

                    display: flex;
                    width: 40px;
                    height: 40px;
                    background-color: $roxo;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;

                    h1 {

                        font-size: 28px;
                        color: $amarelo;

                    }

                }

                .storylineText {

                    padding: 0 1.5rem;

                    h3 {

                        color: $branco;

                    }

                    h4 {

                        margin: .5rem 0;
                        font-weight: 400;
                        color: $branco;
                        opacity: .6;

                    }

                    p {

                        color: $amarelo;

                    }

                }

                #A {

                    grid-area: A;
                    text-align: right;
                    align-self: initial;

                    a {

                        text-decoration: none;
                        color: $vinhoCopa;
                        font-weight: 700;

                    }

                }

                #B {

                    grid-area: B;
                    z-index: 2;

                }

                #C {

                    grid-area: C;
                    z-index: 2;

                }

                #D {

                    grid-area: D;
                    text-align: left;

                }

                #E {

                    grid-area: E;
                    text-align: right;

                }

                #F {

                    grid-area: F;
                    z-index: 2;

                }

                #G {

                    grid-area: G;
                    z-index: 2;

                }

                #H {

                    grid-area: H;
                    text-align: left;

                }

                #I {

                    grid-area: I;
                    text-align: right;

                }

                #J {

                    grid-area: J;
                    z-index: 2;

                }

            }

            #buttonRegister {

                margin: 3rem 0;
                color: $amarelo;
                text-decoration: none;
                border: 2.5px solid $roxo;
                padding: .5rem 3rem;
                border-radius: 10px;

                &:hover {

                    background-color: $roxo;
                    color: $branco;
                    transition: all .3s;

                }

            }

        }

    }

    #OurDifferential {

        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;

        .imgRegister {

            width: 100%;
            position: relative;

            img {

                width: 100%;

            }

            span {

                display: flex;
                position: absolute;
                left: 3%;
                top: 10%;
                color: $amarelo;
                text-transform: uppercase;
                width: 35%;
                font-size: 14vw;
                font-weight: 700;
                opacity: .6;
                user-select: none;

            }

        }

        .textDifferential {

            display: flex;
            width: 100%;
            height: auto;
            background-color: #f5f5f5;
            position: relative;
            flex-direction: column;
            justify-content: center;

            h1 {

                padding: 2rem 0 1rem 2rem;
                font-size: 2.5rem;
                color: #242323;

            }

            p {

                color: $preto;
                opacity: .7;
                padding: 0 2rem 1rem 2rem;
                line-height: 1.5rem;

            }

            a {

                color: $roxo;
                text-decoration: none;
                margin: 0 0 1rem 2rem;
                font-weight: 700;

                &:hover {

                    color: #242323;
                    transition: all .2s;

                }

            }

        }

    }

    #PSInfos {

        background-image: url("../../imgs/pattern.png");
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .infosContent {

            color: $branco;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 3rem;

            h1 {

                font-size: 30px;
                text-transform: uppercase;
                letter-spacing: 3px;
                color: $amarelo;

            }

            ul {

                list-style: none;

                li {

                    padding: 1rem 2rem;
                    text-align: center;
                    font-weight: 500;

                    a {

                        text-decoration: none;
                        color: $amarelo;
                        font-weight: 700;

                    }

                }

            }

            .aboutUsPS {

                display: flex;
                flex-direction: column;
                width: 80vw;
                height: 80vh;
                background-color: #0f0f0f;
                border-radius: 5px;
                justify-content: center;
                margin: 2rem 0 4rem 0;
                align-items: center;
                position: relative;

                h1 {

                    margin-top: 1rem;

                }

                p {

                    padding: 2rem 2rem 0 2rem;
                    z-index: 2;

                }

                a {

                    margin-top: 4rem;
                    text-decoration: none;
                    padding: .7rem 2rem;
                    background-color: $branco;
                    color: $roxo;
                    width: 20%;
                    font-weight: 700;
                    border-radius: 2px;
                    z-index: 2;

                    &:hover {

                        background-color: $preto;
                        color: $branco;
                        transition: all .2s;

                    }

                }

                .imgPSWrapper {

                    display: flex;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    justify-content: center;
                    
                    img {
    
                        width: auto;
                        opacity: .4;
    
                    }
    
                }

            }

        }

    }

    #videoPSSection {

        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;

        h1 {

            font-size: 30px;
            color: $amarelo;
            margin-bottom: 1rem;
            text-transform: uppercase;

        }

        video {

            width: 80vw;

        }

    }
}

@media screen and (max-width: 768px) {

    #mainPS {

        #heroSectionPS {

            .heroWrapperPS {

                .logoPSWrapper {

                    display: flex;
                    width: auto;
                    height: auto;
                    position: absolute;
                    z-index: 2;
                    align-items: center;
                    justify-content: center;
                    top: 10vh;
                    user-select: none;

                    img {

                        width: 40%;

                    }
                    
                }
                
                .textHome {

                    display: flex;
                    width: 96vw;
                    height: 100vh;
                    position: absolute;
                    z-index: 2;
                    align-items: center;
                    justify-content: center;
                    top: 00vh;
                    user-select: none;

                    h1 {

                        bottom: 20%;
                        font-size: 3.5rem;
                        text-align: center;
                        -webkit-text-stroke-width: 2px;
                        -webkit-text-stroke-color: $branco;

                    }

                    h4 {

                        font-size: 85%;
                        bottom: 7%;
                        text-align: center;

                    }

                    a {

                        img {

                            width: 50px;

                        }

                    }

                }

            }

            .sendBackHome {

                top: 2%;
                right: 0%;
                
                img {

                    width: 45%;
                    
                }
                
            }

        }

        #PSInfosSection {

            .introPSInfos {

                padding: 0 1rem;

                h1 {

                    font-size: 2.5rem;

                }

                p {

                    text-align: justify;
                    text-indent: 2em;

                }

            }

            #PSPhases {

                h2 {

                    padding: 4rem 0 2rem 0;

                }

                .PSStoryline {

                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows:  0.3fr 1fr 0.3fr 1fr 0.3fr 1.2fr 0.3fr 1fr 0.3fr 1fr;
                    grid-column-gap: 0px;
                    grid-row-gap: 5px;

                    .storyline {
                        
                        display: none;

                    }

                    .boxStoryline {

                        display: flex;
                        width: 40px;
                        height: 40px;
                        background-color: $roxo;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;
                        justify-self: center;

                        h1 {

                            font-size: 28px;
                            color: $amarelo;

                        }

                    }

                    .storylineText {

                        padding: 0 .3rem;

                        h3, h4, p {

                            text-align: center;

                        }

                        p {

                            padding: 0 .5rem;

                        }

                    }

                    #B {

                        grid-area: 1 / 1 / 2 / 2; 
    
                    }
    
                    #A {
    
                        grid-area: 2 / 1 / 3 / 2;
    
                    }
    
                    #C {
    
                        grid-area: 3 / 1 / 4 / 2;
    
                    }
    
                    #D {
    
                        grid-area: 4 / 1 / 5 / 2;
    
                    }
    
                    #F {
    
                        grid-area: 5 / 1 / 6 / 2; 
    
                    }
    
                    #E {
    
                        grid-area: 6 / 1 / 7 / 2;
    
                    }
    
                    #G {
    
                        grid-area: 7 / 1 / 8 / 2;
    
                    }
    
                    #H {
    
                        grid-area: 8 / 1 / 9 / 2;
    
                    }
    
                    #J {
    
                        grid-area: 9 / 1 / 10 / 2;
    
                    }
    
                    #I {
    
                        grid-area: 10 / 1 / 11 / 2;
    
                    }

                }

                #buttonRegister {

                    margin: 3rem 0;
                    color: $amarelo;
                    text-decoration: none;
                    border: 2.5px solid $roxo;
                    padding: .5rem 3rem;
                    border-radius: 10px;

                    &:hover {

                        background-color: $roxo;
                        color: $branco;
                        transition: all .3s;

                    }

                }

            }

        }

        #OurDifferential {

            width: 100vw;
            height: auto;
            display: flex;
            flex-direction: column;

            .imgRegister {

                width: 100%;
                height: 100%;
                position: relative;

                img {

                    width: 100%;
                    height: 100%;

                }

                span {

           
                    left: 3%;
                    bottom: 5%;
                    width: 65%;

                }

            }

            .textDifferential {

                display: flex;
                width: 100%;
                height: auto;

            }

        }

        #PSInfos {

            .infosContent {

                .aboutUsPS {

                    height: auto;
                    margin: 2rem 0 0 0;
                    width: 90vw;

                    h1 {

                        margin-top: 1rem;
                        font-size: 20px;

                    }

                    p {

                        padding: 2rem 1rem 0 1rem;

                    }

                    a {

                        margin-bottom: 2rem;
                        width: 40%;
                        z-index: 3;

                    }

                    .imgPSWrapper {

                        display: flex;
                        height:  auto;
                        width: 100%;
                        position: absolute;
                        justify-content: center;
                        
                        img {
        
                            width: 100%;
                            opacity: .4;
        
                        }
                    }

                }

            }

        }

        #videoPSSection {

            height: auto;
            margin-bottom: 1rem;
            margin-top: 2rem;

            video {

                width: 90vw;

            }

        }
    }
    
}
