@import "../../global.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

* {

    font-family: 'Source Sans Pro', 'Helvetica', 'Arial', sans-serif;

}

main {

    background-color: #000;

    #PostSection {

        display: flex;
        width: 100vw;
        flex-direction: column;
        align-items: center;

        .authorInfos {

            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;

            .postImageWrapper {
    
                display: flex;
                max-width: 30%;
                max-height: 30%;
                margin: 6rem 2rem 0 2rem;
    
                img {
    
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
    
                }
    
            }

            .authorWrapper {

                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                margin: 0;
                color: #fff;
                font-size: 2.5vw;
                font-family: 'Fira Mono', monospace;
                letter-spacing: -4px;

                .pictureWrapper {

                  display: flex;
                  width: 150px;
                  height: 150px;

                  img {

                    max-width: 100%;
                    object-fit: cover;
                    border-radius: 150px;

                  }

                }

                .hashtags {

                  display: flex;
                  flex-wrap: wrap;
                  width: 70%;
                  margin-top: 1.5rem;
                  letter-spacing: 0px;

                  span {

                    margin: 3px 5px;
                    font-size: 16px;
                    background-color: $roxo;
                    padding: 2px 4px;
                    border-radius: 3px;
                    color: $amarelo;
                    font-weight: 600;
                    cursor: default;

                  }

                }

                h1{
                    animation: glitch 1s linear infinite;
                  }
                  
                  @keyframes glitch{
                    2%,64%{
                      transform: translate(2px,0) skew(0deg);
                    }
                    4%,60%{
                      transform: translate(-2px,0) skew(0deg);
                    }
                    62%{
                      transform: translate(0,0) skew(5deg); 
                    }
                  }
                  
                  h1:before,
                  h1:after{
                    content: attr(title);
                    position: absolute;
                    left: 0;
                  }
                  
                  h1:before{
                    animation: glitchTop 1s linear infinite;
                    clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
                    -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
                  }
                  
                  @keyframes glitchTop{
                    2%,64%{
                      transform: translate(2px,-2px);
                    }
                    4%,60%{
                      transform: translate(-2px,2px);
                    }
                    62%{
                      transform: translate(13px,-1px) skew(-13deg); 
                    }
                  }
                  
                  h1:after{
                    animation: glitchBotom 1.5s linear infinite;
                    clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
                    -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
                  }
                  
                  @keyframes glitchBotom{
                    2%,64%{
                      transform: translate(-2px,0);
                    }
                    4%,60%{
                      transform: translate(-2px,0);
                    }
                    62%{
                      transform: translate(-22px,5px) skew(21deg); 
                    }
                  }

            }

        }

        .postInfos {

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            h3, p {

                color: #fff;

            }

            h1 {
    
                font-size: 2.65rem;
                font-weight: 700;
                margin: 5rem 0 2rem 0;
                color: #dbb826;
                width: 80%;
    
            }

            h3 {

                padding: 0 3rem;

            }

            .paragraphPost {

                width: 90%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: justify;

                p {

                    width: 100%;
                    text-align: justify;
                    color: $branco;
                    line-height: 1.7rem;
                    text-indent: 2em;
                    white-space: pre-line;
                    margin-top: 2rem;
                    font-family: "Montserrat", sans-serif;
                
                }

            }

            a {

              margin-top: 2rem;
              padding: 1rem 2rem;
              border-radius: 5px;
              background-color: $roxo;
              color: #fff;
              font-weight: 700;
              text-decoration: none;
              transition: .2s ease-in-out;

              &:hover {

                background-color: #4d037e;

              }

            }

        }

    }

}

@media screen and (max-width: 768px) {

  main {

    #PostSection {

        .authorInfos {

            margin-top: 8rem;
            // background-color: red;
            flex-direction: column;

            .postImageWrapper {
    
                max-width: 100%;
                margin: 0 1rem;
    
            }

            .authorWrapper {

                flex-direction: column;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                margin: 2rem 0;

                .textInfos {

                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  align-items: center;
                  justify-content: center;

                  h1 {

                    color: #fff;
                    font-size: 6vw;
                    letter-spacing: -2px;
  
                  }

                  h5 {

                    font-size: 24px;

                  }

                }

                .pictureWrapper {

                  width: 100px;
                  height: 100px;

                  img {

                    max-width: 100%;
                    border-radius: 150px;

                  }

                }

                .hashtags {

                  width: 80%;
                  align-items: center;
                  justify-content: center;

                  span {

                    margin: 3px 5px;
                    font-size: 16px;
                    background-color: $roxo;
                    padding: 2px 4px;
                    border-radius: 3px;
                    color: $amarelo;
                    font-weight: 600;
                    cursor: default;

                  }

                }

                h1{
                    animation: glitch 1s linear infinite;
                  }
                  
                  @keyframes glitch{
                    2%,64%{
                      transform: translate(2px,0) skew(0deg);
                    }
                    4%,60%{
                      transform: translate(-2px,0) skew(0deg);
                    }
                    62%{
                      transform: translate(0,0) skew(5deg); 
                    }
                  }
                  
                  h1:before,
                  h1:after{
                    content: attr(title);
                    position: absolute;
                    left: 0;
                  }
                  
                  h1:before{
                    animation: glitchTop 1s linear infinite;
                    clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
                    -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
                  }
                  
                  @keyframes glitchTop{
                    2%,64%{
                      transform: translate(2px,-2px);
                    }
                    4%,60%{
                      transform: translate(-2px,2px);
                    }
                    62%{
                      transform: translate(13px,-1px) skew(-13deg); 
                    }
                  }
                  
                  h1:after{
                    animation: glitchBotom 1.5s linear infinite;
                    clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
                    -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
                  }
                  
                  @keyframes glitchBotom{
                    2%,64%{
                      transform: translate(-2px,0);
                    }
                    4%,60%{
                      transform: translate(-2px,0);
                    }
                    62%{
                      transform: translate(-22px,5px) skew(21deg); 
                    }
                  }

            }

        }

        .postInfos {

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            h3, p {

                color: #fff;

            }

            h1 {
    
                font-size: 1.5rem;
                margin: 2rem 0 2rem 0;
                width: 90%;
    
            }

            h3 {

                padding: 0 1rem;

            }

        }

    }

  }

}