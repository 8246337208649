@import '../../global.scss';

.teste {
    position: fixed;
    top: 90%;
    right: 2%;
    
    img {
        height: 50px;
    }
    
}

@media screen and (max-width: 768px) {
    .teste {
        right: 5%;
    }
}